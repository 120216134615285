import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

export const About = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#F5F4F6";
    document.body.style.color = "black";

    return () => {
      document.body.style.backgroundColor = "#3B3255";
      document.body.style.color = "white";
    };
  }, []);

  return (
    <Container
      style={{ position: "relative", background: "#f5f4f6", zIndex: 1, top: 0 }}
    >
      <Row className='justify-content-center'>
        <Col lg={8}>
          <div className='pages-title-div'>
            <h1 className='pages-title'>About us</h1>
          </div>
          <div className={"pages-content"}>
            <p>
              Article Lab is a dynamic collective of polyglots, polymaths, mavericks, and dreamers.
              Our team is passionately engaged in designing, researching, and crafting innovative
              tools that harness the power of Artificial Intelligence (AI). We are committed to
              addressing the AI landscape's challenges, particularly in content creation.
            </p>
            <p>
              As the popularity of AI-assisted content creation continues to surge, the digital
              world faces an increasing risk of being inundated with outdated, misleading, and
              AI-hallucinated information. This is a challenge we are dedicated to combating. We
              believe in the power of accurate, relevant, and timely information, and we strive to
              ensure that the digital landscape remains a trustworthy source of knowledge.
            </p>
            <p>
              Article Lab is proud to offer a suite of tools designed to facilitate the creation of
              easy-to-read, easy-to-find, and up-to-date articles. Our specialty is crafting
              long-format articles, ranging from 600 to 3000 words. Our ability to update
              information instantly from news sources and other web-based resources sets us apart at
              the moment of article creation. This ensures that our content is comprehensive but
              also current and relevant.
            </p>
            <p>
              Our team is composed of experienced professional writers who have leveraged their
              expertise to create a tool that makes AI work for you rather than the other way
              around. With Article Lab, you are freed from the need to create prompts or tweak AI
              instructions. Instead, you can focus on what truly matters: the subject you wish to
              delve into, your unique perspective, your target audience, and your distinctive
              writing style.
            </p>
            <p>
              Article Lab takes care of the rest. We provide thoroughly researched, up-to-date
              articles within minutes. Our tool also includes optimized keywords tailored to your
              subject matter and audience. This ensures that your content is engaging, informative,
              and SEO-friendly.
            </p>
            <p>
              We are here to help you navigate the AI landscape, ensuring your content remains
              relevant, accurate, and engaging in an ever-evolving digital world.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
