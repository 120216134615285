import { Button, Form } from "react-bootstrap";
import React from "react";
import { useOutletContext } from "react-router";

export const Personas = () => {
  const { uploadedFile, setUploadedFile } = useOutletContext();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file.type === "text/plain") {
      setUploadedFile(file);
    } else {
      alert("Only plain text files are allowed.");
      event.target.value = null;
    }
  };
  const handleFileUploadSubmit = () => {
    if (uploadedFile && uploadedFile.size <= 1500 * 1024) {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      fetch("/public/uploads", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          alert("File uploaded successfully");
        })
        .catch((error) => {
          console.error("There was a problem with the file upload:", error);
        });
    } else {
      alert("Please upload a plain text file with up to 1,500 words.");
    }
  };
  return (
    <div className="Input-box-file own-page" style={{ padding: 0 }}>
      <Form.Group controlId="formFileSm" className="mb-3">
        <Form.Label htmlFor="voiceFile" style={{ fontSize: "small", maxWidth: "17rem" }}>
          Create your own voice. Upload a text file with text samples of your writing style:
        </Form.Label>
        <Form.Control
          type="file"
          id="voiceFile"
          accept=".txt"
          onChange={handleFileUpload}
          size="sm"
        />
      </Form.Group>
      <Button onClick={handleFileUploadSubmit} className="Submit-button">
        Upload Text File
      </Button>
    </div>
  );
};
