import React from 'react';
import '../../App.css';

const Banner = ({ show, message,onClose }) => {
    return (
        show && (
            <div className="backdrop">
                <div className="banner" onClick={onClose}>
                    {message ? message : 'Inappropriate content detected. Please modify your input.'}
                    <span className="banner-click-to-modify">. Click here to modify the content.</span>
                </div>
            </div>
        )
    );
};

export default Banner;
