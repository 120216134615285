import { Col, Container, Nav, Row } from "react-bootstrap";
import { Navbar } from "react-bootstrap/esm/index.js";
import { LinkWithHover } from "./LinkWithHover.js";


export const Footer = () => {
  return (
    <Row className='App-footer' style={{ zIndex: 10 }}>
      <Container fluid as='footer'>
        <Row>
          <Col className='py-3' md='auto'>
            © Article Lab AI LLC. All rights reserved.
          </Col>
          <Col>
            <Row>
              <Nav>
                <Navbar className='navbar-wide' expand>
                  <Col style={{ width: "fit-content" }} className="d-md-flex" md={6}>
                  <LinkWithHover link='/about' text='About us' />
                  <LinkWithHover link='https://blog.articlelab.ai' text='Blog' external/>
                  <LinkWithHover link='https://articlelab.getrewardful.com/signup' text='Affiliates' external/>
                  <LinkWithHover link='/contact' text='Contact us' />
                  </Col>
                  <Col style={{ width: "fit-content" }} className="d-md-flex" md={6}>
                  <LinkWithHover link='/faq' text='FAQs' />
                  <LinkWithHover link='/pricing' text='Pricing' />
                  <LinkWithHover link='/privacy' text='Privacy policy' />
                  <LinkWithHover link='/tos' text='Terms of service' />
                  </Col>
                </Navbar>
              </Nav>
            </Row>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};
