import { Button, Container, Row } from "react-bootstrap";
import Header from "./Header.js";
import { Footer } from "./index.js";
import { useEffect } from "react";
import { useNavigate } from "react-router";

export const Error = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.backgroundColor = "#F5F4F6";
    document.body.style.color = "black";

    return () => {
      document.body.style.backgroundColor = "#3B3255";
      document.body.style.color = "white";
    };
  }, []);
  return (
    <Container fluid style={{ width: "100%" }}>
      <Row className='App-header fixed-top'>
        <Header />
      </Row>
      <Container fluid className='main-content'>
        <Row className='d-flex justify-content-center'>
          <img src='/images/404.png' alt='404' style={{ width: "40rem" }} />
        </Row>
        <Row className='d-flex justify-content-center'>
          <h2 style={{ textAlign: "center", fontFamily: "Inter" }}>
            Oops! Looks like this page does not exist.
          </h2>
          <h2 style={{ textAlign: "center", fontFamily: "Inter" }}>
            If you think it’s a problem with us please{" "}
            <button className='text-button' onClick={() => navigate("/contact")}>
              tell us.
            </button>
          </h2>
          <Button style={{ maxWidth: "10rem" }} onClick={() => navigate("/")}>
            Go home
          </Button>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
};
