import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

const useAuthentication = () => {
  const [status, setStatus] = useState({ active: false, price: "", type: "", endDate: 0 });
  const [loading, setLoading] = useState(true);
  const { user, isLoading } = useAuth0();
  const userPipe = user?.sub.split("|")[1];

  useEffect(() => {
    const fetchAuth = async () => {
      if (!user && !isLoading) {
        setLoading(false);
        return;
      }
      if (user !== undefined) {
        const response = await fetch(`/check-customer/${userPipe}`, {
          method: "GET",
        });
        if (response.status === 403) {
          const res = await response.json();
          if (res.message === "Subscription paused") {
            setStatus({ active: false, price: "", type: "paused", endDate: 0 });
          } else if (res.message === "Subscription canceled") {
            setStatus({ active: false, price: "", type: "canceled", endDate: 0 });
          }
          setLoading(false);
        }
        if (response.ok) {
          const res = await response.json();
          setLoading(false);
          setStatus({
            active: true,
            price: await res.price,
            endDate: await res.end_date,
            type: (await res.message) === "Trial active" ? "trial" : "paying",
          });
        }
      }
    };
    fetchAuth();
  }, [user?.sub, isLoading]);

  //returns the user object from Auth0, whether the subscription is active/on trial (true)
  //or non existant/canceled (false), whether we're loading the subscription status
  //and the price code of the subscription
  return { user, status, loading };
};

export default useAuthentication;