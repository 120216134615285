export const TosContent =  {

	title: "Terms of Service",
		lastUpdated: "May 22, 2023",
		welcome: "Welcome to Article Lab AI, a service providing AI-assisted article writing. Please read these Terms of Service carefully before using our service.",
		sections: [
			{
				"Title": "Acceptance of Terms",
				"Content": "By using our service, you agree to be bound by these Terms of Service. If you do not agree with these terms, please do not access or use the service."
			},
			{
				"Title": "Description of Service",
				"Content": "Article Lab AI provides an AI-based tool to assist in article writing, designed to moderate users' input to comply with the terms of service of our partners."
			},
			{
				"Title": "User Responsibilities",
				"Content": "As a user, you maintain full responsibility for the content you create using Article Lab AI. We do not claim ownership or control over your content, but by using our service you agree to comply with all relevant laws and regulations and not to use the service for any unlawful purpose."
			},
			{
				"Title": "Intellectual Property Rights",
				"Content": "The content you create using Article Lab AI is your sole property, and you hold the copyright to this content. However, by using our service, you grant us a license to use, reproduce, display and distribute your content for the purpose of providing, improving and promoting our service."
			},
			{
				"Title": "Loss of Information",
				"Content": "While we implement reasonable measures to protect your content, we do not guarantee that there will be no loss of data or information. You are responsible for maintaining appropriate backups of your content."
			},
			{
				"Title": "Right to Terminate Services",
				"Content": "We reserve the right to suspend or terminate your access to our services at our sole discretion, without notice or liability, for any reason, including but not limited to the violation of these Terms of Service, illegal activities, or use of methods that attempt to circumvent the standard interface, including scraping, automation, or other similar activities. We may also suspend or terminate your access to our services for any violation of the Terms of Service of our partners."
			},
			{
				"Title": "Indemnification",
				"Content": "You agree to defend, indemnify, and hold harmless Article Lab AI, its affiliates, and its personnel, from and against any and all claims, damages, obligations, losses, liabilities, costs or debts, and expenses (including but not limited to attorney's fees) resulting from your use of and access to the Service, including any data or content transmitted or received by you, any violation by you of these terms, any third-party right, including without limitation any copyright, property, or privacy right; or any claim that your use of the service caused damage to a third party."
			},
			{
				"Title": "Disclaimer of Warranties",
				"Content": "Article Lab AI is provided 'AS IS.' We, our affiliates and licensors, make no express or implied warranties or guarantees about the Service. To the maximum extent permitted by law, we disclaim all warranties, statutory, express or implied, including implied warranties of merchantability, fitness for a particular purpose, non-infringement, and any warranties arising out of any course of dealing or trade usage. We do not guarantee that the Service will meet your requirements, be error-free, reliable, without interruption or available at all times."
			},
			{
				"Title": "Limitation of Liability",
				"Content": "To the maximum extent permitted by law, neither Article Lab AI nor any of its affiliates or licensors will be liable for any indirect, incidental, special, consequential, or exemplary damages, including damages for loss of profits, goodwill, use, or data, even if we have been advised of the possibility of such damages. The total liability of Article Lab AI, for any claim under these terms, including for any implied warranties, is limited to the amount you paid us to use the applicable service."
			},
			{
				"Title": "Changes to the Service",
				"Content": "We reserve the right to modify, suspend or discontinue, temporarily or permanently, the Service (or any part thereof) at any time with or without notice. You agree that we will not be liable to you or to any third party for any modification, suspension or discontinuance of the Service."
			},
			{
				"Title": "Governing Law",
				"Content": "These Terms of Service shall be governed by the laws of the State of New York, United States. Any disputes relating to these Terms of Service shall be resolved in the relevant courts of New York City, New York."
			},
			{
				"Title": "Modification of Terms",
				"Content": "We reserve the right to modify these Terms of Service at any time. Any changes will be effective immediately upon posting, and your continued use of the service will indicate your acceptance of the revised terms."
			},
			{
				"Title": "Final Provisions",
				"Content": "If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Terms will otherwise remain in full force and effect and enforceable. Our failure to enforce any part of these Terms shall not constitute a waiver of our right to later enforce that or any other part of these Terms."
			}
		],

	contact: {
		title: "8. Contact Us",
			content:
		"If you have any questions about these Terms of Service, please contact us at support@articlelab.ai.",
	},
};