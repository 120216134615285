import React, { useState, useEffect } from "react";
import useAuthentication from "../hooks/useAuthentication.js";
import { Loading } from "./Loading.js";
import { Row, Col, Image } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";


export const MyAccount = () => {
  const { user, status, loading } = useAuthentication();
  const { isLoading } = useAuth0();

  return loading ? (
    <Loading />
  ) : status && (
    <SuccessDisplay />
  )
};

const SuccessDisplay = () => {
  const { user, status, loading } = useAuthentication();
  const [panel, setPanel] = useState("0");
  const userPipe =user.sub.split('|')[1];
  const [productData, setProductData] = useState([])

  const priceTranslator = (priceId) => {
    for (const product of productData) {
      for (const price of product.prices) {
        if (price.id === priceId) {
          return product.name;
        }
      }
    }
    return "";
  };

  const fetchPrices = async () => {
    const response = await fetch("/get-prices", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } 

  useEffect(() => {
    fetchPrices().then((data) => {
      data.sort((a, b) => a.featured - b.featured
      );
      setProductData(data);
    }
    );
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "#F5F4F6";
    document.body.style.color = "black";

    return () => {
      document.body.style.backgroundColor = "#3B3255";
      document.body.style.color = "white";
    };
  }, []);


  return loading ? (
    <Loading />
  ) : (
    <>
      <Row className='justify-content-center'>
        <Col style={{ margin: "3rem 0" }} xs={3}>
          <h1 style={{ fontWeight: "bold", textAlign: "center", color: "#3B3255" }}>Account</h1>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-column align-items-center' lg={3}>
          <Image
            fluid
            roundedCircle
            style={{ width: "7rem", height: "7rem" }}
            src={user.picture}
            alt='User profile'
          />
          <Row className='mt-2'>
            <p>{user.name}</p>
          </Row>
          <Row>
            <button
              className={`text-button account-nav ${panel === "0" ? "selected" : "" }`}
              onClick={() => setPanel("0")}
            >
              Personal information
            </button>
          </Row>
          <Row>
            <button
              className={`text-button account-nav ${panel === "1" ? "selected" : "" }`}
              onClick={() => setPanel("1")}
            >
              Billing/Payments
            </button>
          </Row>
        </Col>
        {panel === "0" ? (
          <Col lg={9}>
            <Row>
              <h3 style={{color: "#3B3255", fontWeight: "bold" }}>Personal information</h3>
              <p>
                Manage your personal information, including information where you can be reached
              </p>
            </Row>
            <Row>
              <Col md={4} className='border border-2 p-4 m-2'>
                <Row as='h5'>Name</Row>
                <Row>{user.name}</Row>
              </Col>
              <Col md={4} className='border border-2 p-4 m-2'>
                <Row as='h5'>Email</Row>
                <Row>{user.email}</Row>
              </Col>
            </Row>
            <Row>

            </Row>
          </Col>
        ) : (
          <Col lg={9}>
            <Row>
              <h3 style={{ color: "#3B3255", fontWeight: "bold" }}>Billing/payments</h3>
              <p>Manage your billing details</p>
            </Row>
            <Row>
              <Col md={4} className='border border-2 p-4 m-2'>
                <Row as='h5'>My plan</Row>
                <Row>{`${priceTranslator(loading ? null : status.price)} ${
                  status.type === "trial" ? "(trial)" : ""
                }`}</Row>
              </Col>
              <Col md={4} className='border border-2 p-4 m-2'>
                <Row as='h5'>Manage my subscription</Row>
                <Row>
                  <form action='/create-portal-session' method='POST'>
                    <input type='hidden' id='user' name='user' value={userPipe} />
                    <button className='text-button' id='checkout-and-portal-button' type='submit'>
                      Manage your billing information
                    </button>
                  </form>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={4} className='border border-2 p-4 m-2'>
                <Row as='h5'>End date</Row>
                <Row>
                  {!status.active
                    ? `You're not currently subscribed.`
                    : status.type === "trial"
                    ? `Your trial will end on ${new Date(status.endDate * 1000).toLocaleString()}`
                    : `Your subscription will renew on ${new Date(
                        status.endDate * 1000
                      ).toLocaleString()}`}
                </Row>
              </Col>

            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function App() {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

  if (!success && message === "") {
    return <MyAccount />;
  } else if (success && sessionId !== "") {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}
