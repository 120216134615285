import { useEffect } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
//import faqData from './faqData.json'; // import JSON data from local file
const faqData = [
  {
    question: "What's the estimated time for crafting an article?",
    answer:
      "With our AI-driven approach, you'll have a well-researched, SEO-optimized, ready-to-publish article in a matter of 2 to 8 minutes. We focus on content quality, relevancy, and recency, which are more important than keywords to attract readers to your site. Our AI-powered content creation process ensures that you get quality content without burning through the day with writing.",
  },
  {
    question: "What's your secret recipe for creating an article?",
    answer:
      "Our secret ingredient is an AI that swiftly analyzes hundreds of data sources to deliver current and relevant content. We strike a balance between creating engaging content your readers will love, and optimizing for SEO to ensure better search engine visibility.",
  },
  {
    question: "Is there any risk of plagiarism with your service?",
    answer:
      "Not at all! Our AI respects intellectual property rights and generates wholly original content. We use advanced AI techniques to confirm the uniqueness of each article. In case of any plagiarism detection, the content is immediately replaced. We take pride in our commitment to respecting copyrights.",
  },
  {
    question: "How do you ensure SEO-optimization for the content?",
    answer:
      "Our SEO strategy goes beyond keyword stuffing. We focus on Expertise, Authority, and Trust (EAT), enriching your content with latest information and our unique keyword optimization algorithm. On top of that, we format the content to be easily readable and engaging. So, you're not only providing value to your readers, but also climbing the SERPs ladder.",
  },
  {
    question:
      "How can AI accelerate the process of comprehensive and well-researched article creation?",
    answer:
      "Our AI is a boon for content creators, designers, bloggers, and researchers. It efficiently analyzes numerous data sources to generate relevant and current information in less than 2 minutes. Plus, it takes care of SEO optimization, ensuring your content gets the visibility it deserves.",
  },
  {
    question: "Can I trust the accuracy and relevancy of your AI-generated articles?",
    answer:
      "Absolutely! Our AI is built to deliver reliable information from trusted sources while being in sync with the latest trends. Also, each article is SEO-optimized, helping your content gain the right exposure.",
  },
  {
    question: "Can I customize the AI-generated articles to suit my needs?",
    answer:
      "Definitely! We believe in versatility, and our AI caters to that. Customize the formatting, integrate images or videos, add custom tags, and much more to make the article resonate with your brand voice. Save your time and energy and let our AI handle the content crafting!",
  },
  {
    question: "How does your AI embed SEO best practices into each article?",
    answer:
      "Our AI is equipped to seamlessly weave in SEO best practices within each article. It optimizes keywords, phrases, and text in alignment with search engine algorithms, creating content that's both reader-friendly and SEO-friendly. The result - higher visibility and better ranking on search engine results.",
  },
  {
    question:
      "Who is the target audience for your AI article writing service, and how can it benefit content creators, designers, bloggers and researchers?",
    answer:
      "Our AI article writing service is designed to benefit content creators, designers, bloggers, and researchers. It produces comprehensive, well-researched, up-to-date, and SEO-Optimized complete articles in less than 2 minutes. This means that you can quickly create articles that are of the highest quality at a fraction of the time it would normally take you. Additionally, we use cutting edge technology to ensure that all our articles feature relevant information and are fully formatted. This dramatically simplifies the whole researching and writing process while providing an effective way to save time and energy.",
  },
  {
    question: "Is there a cap on word count or topics for each article?",
    answer:
      "No limitations here! Craft articles on any topic and any from 600 to 3000 words. Every article will be packed with the latest research and information that your readers will find insightful. Enjoy quality content and SEO optimization without spending all day writing. Let our AI do the work!",
  },
  {
    question: "Who should consider using your services?",
    answer:
      "Our service is a perfect match for anyone in need of rapid, SEO-optimized, and accurate content. Whether you're a content writer, online marketer, blogger, influencer, or a media outlet - we've got you covered. In less time than you'd imagine, get quality articles ready to publish!",
  },
  {
    question: "How do you guarantee factually accurate marketing copy?",
    answer:
      "Our AI-powered service delivers factual content based on reliable and pre-vetted sources. This ensures accuracy and up-to-date information. Our natural language processing algorithms also ensure grammatically correct content, making it a joy for readers.",
  },
  {
    question: "How do you maintain SEO-optimization for your content?",
    answer:
      "We leverage AI to ensure optimal keyword density through keyword analysis and natural language processing. Enjoy quick and accurate SEO results to enhance your online presence!",
  },
];

export const Faq = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#F5F4F6";
    document.body.style.color = "black";

    return () => {
      document.body.style.backgroundColor = "#3B3255";
      document.body.style.color = "white";
    };
  }, []);
  return (
    <Container
      style={{ position: "relative", background: "#f5f4f6", zIndex: 1, top: 0 }}
    >
      <Row className='justify-content-center'>
        <Col lg={8}>
          <div className='pages-title-div'>
            <h1 className='pages-title'>Questions We Hear A Lot</h1>
            <Accordion defaultActiveKey='0' flush style={{ marginTop: 80 }}>
              {faqData.map((faq, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header className='faq-header'>{faq.question}</Accordion.Header>
                  <Accordion.Body className='faq-body'>{faq.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
