import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Container,
  Spinner,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  ProgressBar
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useNavigate, useOutletContext } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Banner from './helpers/Banner.js';
import useAuthentication from "../hooks/useAuthentication.js";


const useField = (initial) => {
  const [value, setValue] = useState(initial);
  const [isInvalid, setIsInvalid] = useState(false);
  const onChange = (event) => {
    setValue(event.target.value);
  };

  return {
    value,
    setValue,
    isInvalid,
    setIsInvalid,
    onChange,
  };
};

const CustomTooltip = ({ text }) => {
  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      {text}
    </Tooltip>
  );
  return (
    <OverlayTrigger placement='left' overlay={renderTooltip}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='currentColor'
        className='bi bi-question-circle-fill'
        viewBox='0 0 16 16'
      >
        <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z' />
      </svg>
    </OverlayTrigger>
  );
};

export const ArticleCreation = () => {
  const articleContext = useField("");
  const length = useField("400");
  const persona = useField("");
  const audience = useField("");
  const writingStyle = useField("");
  const fleschScore = useField("60-70");
  const [generatedTitle, setGeneratedTitle] = useState("");
  const [generatedOutline, setGeneratedOutline] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [contentCreationMode, setContentCreationMode] = useState("0");
  const [optimizedKeywords, setOptimizedKeywords] = useState([]);
  const title = useField("");
  const [loadingKeywords, setLoadingKeywords] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState(false)
  const includedKeywords = useField("");
  const [inputOption, setInputOption] = useState("manual");
  const { setTimeTaken, uploadedFile } = useOutletContext();
  const { user, status } = useAuthentication();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const ws = useRef(null);
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const closeBanner = () => {
    setShowBanner(false);
  };
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  
  useEffect(() => {
    window.rewardful(
      "ready",
      () => {
        if (user?.email) {
          window.rewardful("convert", { email: user?.email });
        }
      },
      []
    );
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress >= 100) {
          clearInterval(intervalId);
          return 100;
        }
        return oldProgress + 2; // Here, 1 represents 1% of the progress bar. Adjust this value according to your progress update frequency.
      });
    }, 6000);
    return () => clearInterval(intervalId); // This will clear the interval when the component unmounts.
  }, []);
  const [progressTextIndex, setProgressTextIndex] = useState(0);
  useEffect(() => {
    if (progress === 100) {
      setProgressTextIndex(progressTextArray.length - 1);
    } else {
      const newIndex = Math.floor((progress / 33) * progressTextArray.length);
      setProgressTextIndex(newIndex);
    }
  }, [progress]);

  const progressTextArray = [
    'Writing the title',
    'Creating a thoughtful outline',
    'Defining the table of contents',
    'Generating the first draft',
    'Creating a beautiful cover image',
    'Writing a catchy introduction',
    'Writing most of the article',
    'Thinking about a good closing paragraph',
    'Creating all sorts of snippets',
    'Style checking and proofreading',
    'Grammar checking and plagiarism checking',
    'Adding the human touch',
  ];
    useEffect(() => {
    ws.current = new WebSocket(
      `${window.location.protocol === "https:" ? "wss:" : "ws:"}//${window.location.host}`
    );

    ws.current.onopen = () => {};

    ws.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      handleResponse(response);
    };

    ws.current.onclose = () => {
      // console.log('WebSocket connection closed');
    };


    return () => {
      ws.current.close();
    };
  }, []);

  const userId = user?.sub?.split("|")[1];
  const handleSubmit = async () => {
    if (contentCreationMode === "0" && articleContext.value === "") {
      articleContext.setIsInvalid(true);
      return;
    }
    if (contentCreationMode === "1" || contentCreationMode === "2") {
      if (articleContext.value === "") {
        articleContext.setIsInvalid(true);
      } else articleContext.setIsInvalid(false);
      if (writingStyle.value === "") {
        writingStyle.setIsInvalid(true);
      } else writingStyle.setIsInvalid(false);
      if (persona.value === "") {
        persona.setIsInvalid(true);
      } else persona.setIsInvalid(false);
      if (contentCreationMode === "2") {
        if (title.value === "") {
          title.setIsInvalid(true);
          return;
        } else title.setIsInvalid(false);
      }
      if (
        articleContext.value === "" ||
        writingStyle.value === "" ||
        persona.value === "" ||
        (contentCreationMode === "2" && title.value === "")
      ) {
        return;
      }
    }

    setLoading(true);
    setError("");
    setGeneratedTitle("");
    setGeneratedOutline("");
    setDisableSubmit(true);
    setError("");

    function processKeywords(includedKeywords, optimizedKeywords) {
      let includedKeywordsResult = '';
      if (includedKeywords.value.length ===0 && optimizedKeywords.length ===0) {
        return includedKeywordsResult;
      }

      if(includedKeywords.value.length>0 && optimizedKeywords.length>0 ){
        includedKeywordsResult = includedKeywords.value.map((keyword) => keyword.value).join(",")
            +","+
            optimizedKeywords.map((keyword) => keyword.value).join(",");
      }
      else if (includedKeywords.value.length > 0 && optimizedKeywords.length ===0) {
        includedKeywordsResult = includedKeywords.value.map((keyword) => keyword.value).join(",");
      }
      else if (optimizedKeywords.length > 0 && includedKeywords.value.length ===0 ) {
        includedKeywordsResult = optimizedKeywords.map((keyword) => keyword.value).join(",");
      }
      return includedKeywordsResult;
    }
    const data = {
      contentCreationMode,
      articleContext: articleContext?.value?.replace(/\n\n/gm, ".").replace(/\n/gm, " "),
      title: title?.value,
      length: length?.value,
      persona: persona?.value,
      writingStyle: writingStyle?.value,
      fleschScore: fleschScore?.value,
      includedKeywords:processKeywords(includedKeywords,optimizedKeywords),
      userId,
      audience: audience?.value,
      ownVoice: inputOption === "manual" ? null : uploadedFile?.name,
    };

    sendData(data);
  };
  const startTime = new Date(); // Record the start time
  const sendData = (data) => {
    ws.current.send(JSON.stringify(data));
  };

  const handleResponse = (response) => {
    if (response.status === "") {
      return;
    }
    setError("");

    if (response.status === "error") {
      setError(response.message);
      setDisableSubmit(false);
      return;
    }

    if (response.status) {
      setError(`${response.status}`);
      return;
    } else if (response.clear) {
      setError("");
      return;
    } else {
      setDisableSubmit(false);
      setLoading(false);
      const endTime = new Date(); // Record the end time
      const timeElapsed = (endTime - startTime) / 1000; // Calculate the time taken in seconds
      setTimeTaken(timeElapsed); // Update the state with the time taken
      try {
        setGeneratedOutline(response.completeArticle);
        setGeneratedTitle(response.articleTitle);
        navigate('/documents')
      } catch (e) {
        console.error(e);
        console.error(response);
      }
    }
  };
  const getKeywords = async (articleContext, includedKeywords, setIncludedKeywordsCB) => {
    if (articleContext || includedKeywords) {
      const articleLength = length.value;
      const message = `${articleContext} ${
        includedKeywords.length > 0
          ? includedKeywords.map((keyword) => keyword.value).join(",")
          : ""
      }`;
      try {
        setLoadingKeywords(true);
        const response = await fetch("/get_keywords", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ articleContext: message, articleLength }),
        });
        if(response.status === 406){
          //console.log("Response status:", await response.json());
          const message = await response.json();
          setShowBanner(true);
          setBannerMessage(message.message );
          return;
        }
        else if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        //console.log("Keywords response:", data.keywords);
        setLoadingKeywords(false);
        //turn the data.keywords string into an array of objects
        const keywords = data.keywords.split(",").map((keyword) => {
          return { value: keyword, label: keyword };
        });
        setIncludedKeywordsCB(keywords);
      } catch (error) {
        setLoadingKeywords(false);
        console.error("There was a problem with the keyword request:", error);
      }
    } else {
      alert("Article context cannot be empty.");
    }
  };

  const regenerateTitle = async (titleIn) => {
    try {
      setLoadingTitle(true);
      const response = await fetch("/regenerate_title", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ titleIn }),
      });
      if(response.status === 406){
        //console.log("Response status:", await response.json());
        const message = await response.json();
        setShowBanner(true);
        setBannerMessage(message.message );
        return;
      }
      if (!response.ok) {
        setLoadingTitle(false);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setLoadingTitle(false);
      title.setValue(data.title);
    } catch (error) {
      console.error("There was a problem with the title request:", error);
    }
  };

  const clearAllInvalid = () => {
    articleContext.setIsInvalid(false);
    writingStyle.setIsInvalid(false);
    persona.setIsInvalid(false);
    title.setIsInvalid(false);
  };

  const parseStringOptions = (inputString) => {
    if (typeof inputString !== "string") return inputString;
    const res =
      inputString.length > 1
        ? inputString.split(",").map((value) => ({ value: value.trim(), label: value.trim() }))
        : [];
    return res;
  };

  return (
    <Container>
      {
        showBanner && (
              <Banner
                  show={showBanner}
                  message={bannerMessage}
                  onClose={closeBanner}
              />
      )}

      <Row className='justify-content-center'>
        {!loading ? (
          <Col lg={10}>
            <Row className="mb-4">
              <Col lg={6}>
                <h2 style={{fontFamily:'Ubuntu, sans'}}>Content Creation Mode</h2>
              </Col>
              <Col>
                <ButtonGroup aria-label='Content Creation Mode'>
                  <Button
                    active={contentCreationMode === "0"}
                    onClick={() => {
                      setContentCreationMode("0");
                      clearAllInvalid();
                    }}
                  >
                    Fast
                  </Button>
                  {(status.price === "price_1NFdhcJvGDgYQqOzfKTrgKOP"
                    || status.price === "price_1NFdh4JvGDgYQqOzCo993KAb")
                    && (
                  <Button
                    active={contentCreationMode === "2"}
                    onClick={() => {
                      setContentCreationMode("2");
                      clearAllInvalid();
                    }}
                  >
                    Interactive
                  </Button>
                  )}
                  <Button
                    active={contentCreationMode === "1"}
                    onClick={() => {
                      setContentCreationMode("1");
                      clearAllInvalid();
                    }}
                  >
                    Advanced
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            {contentCreationMode === "0" && (
              <>
                <Row className="mb-3">
                  <Col>
                    <Row className='justify-content-between'>
                      <Col>
                        <Form.Label
                          className='required'
                          style={{ fontSize: "small", alignSelf: "center" }}
                          htmlFor='includedKeywords'
                        >
                          Write a 400-word marketing article about...
                        </Form.Label>
                      </Col>
                      <Col xs='auto'>
                        <CustomTooltip text='Prompt-free Creation: No need to start with a specific prompt. Just focus on the topic you want the Lab to write about. Let the magic happen!' />
                      </Col>
                    </Row>
                  </Col>
                  <div>
                    <Form.Control
                      rows={3}
                      as='textarea'
                      value={articleContext.value}
                      onChange={articleContext.onChange}
                      required
                      isInvalid={articleContext.isInvalid}
                      placeholder='... My company, Inc. and its role in the AI revolution&#10;... developmental needs of monsters&#10;... kittens'
                    />
                    <div className='invalid-feedback'>Please include an outline</div>
                  </div>
                </Row>
              </>
            )}
            {contentCreationMode === "1" && (
              <>
                <Row>
                  <Row style={{ width: "102.6%", maxWidth: "102.6%" }}>
                    <Col className="mb-3">
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label
                            className='required'
                            style={{ fontSize: "small", alignSelf: "center" }}
                            htmlFor='includedKeywords'
                          >
                            Write an article about...
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="Prompt-free Creation: No need to start with a specific prompt. Just focus on the topic you want the Lab to write about. Let the magic happen!" />
                        </Col>
                      </Row>
                      <Form.Control
                        rows={3}
                        as='textarea'
                        value={articleContext.value}
                        onChange={articleContext.onChange}
                        required
                        isInvalid={articleContext.isInvalid}
                        placeholder='... My company, Inc. and its role in the AI revolution&#10;... developmental needs of monsters&#10;... kittens'
                      />
                      <div className='invalid-feedback'>Please include an outline</div>
                    </Col>
                  </Row>
                  <Col md={6}>
                    <Form.Group className='mb-3'>
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label
                            className='required'
                            style={{ fontSize: "small" }}
                            htmlFor='writingStyle'
                          >
                            Using this writing style:
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="Feel free to define your desired tone and approach.
                          Be it friendly, academic, or investigative journalism, the Lab will emulate your unique style.
                          Unleash your creativity and craft articles that truly resonate with your voice!" />

                        </Col>
                      </Row>
                      <Col>
                        <Form.Control
                          isInvalid={writingStyle.isInvalid}
                          size='sm'
                          type='text'
                          id='writingStyle'
                          value={writingStyle.value}
                          onChange={writingStyle.onChange}
                          required
                          placeholder='journalistic, friendly, general, expository, persuasive...'

                        />
                        <div className='invalid-feedback'>Please include the writing style</div>
                      </Col>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label
                            className='required'
                            style={{ fontSize: "small" }}
                            htmlFor='persona'
                          >
                            From the point of view of:
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="Specify your desired perspective here.
                          Whether you want the article to sound like it's from you, an expert in your field, or someone your audience cares about, this information helps tailor the content to be as unique and impactful as possible. Express yourself through AI!" />
                        </Col>
                      </Row>
                      <Col>
                        <Form.Control
                          size='sm'
                          type='text'
                          id='persona'
                          value={persona.value}
                          onChange={persona.onChange}
                          isInvalid={persona.isInvalid}
                          placeholder='a journalist, an online marketeer, Peter Pan, a foodie, a teacher...'
                          required
                        />
                        <div className='invalid-feedback'>Please include the point of view</div>
                      </Col>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label style={{ fontSize: "small" }} htmlFor='audience'>
                            For an audience of:
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="Define your intended audience here. This helps shape the content's language, depth, and relevance. Whether you're targeting beginners, tech-savvy readers, or seasoned professionals, setting the audience helps deliver an article that resonates with your specific reader group. Shape your narrative for maximum impact!" />
                        </Col>
                      </Row>
                      <Col>
                        <Form.Control
                          size='sm'
                          type='text'
                          id='audience'
                          value={audience.value}
                          onChange={audience.onChange}
                          placeholder='content creators, school moms, tycoons, gourmands, students...'
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label style={{ fontSize: "small" }} htmlFor='fleschScore'>
                            With this Flesch Score:
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="This readability score indicates how easy it is to understand a text. A higher score suggests simpler, more easily understood content, while a lower score indicates more complex language. By default, we set it at 60-70, a balance suitable for a wide audience, from high school students to professionals. Tailoring your Flesch Score helps align your content's complexity with your audience's comprehension level, enhancing reader engagement and satisfaction." />
                        </Col>
                      </Row>
                      <Form.Select
                        size='sm'
                        id='fleschScore'
                        value={fleschScore.value}
                        onChange={fleschScore.onChange}
                        required
                      >
                        <option value='0-30'>0-30 (college graduate to professional)</option>
                        <option value='30-50'>30-50 (college)</option>
                        <option value='50-60'>50-60 (10th to 12th grade)</option>
                        <option value='60-70'>60-70 (8th & 9th grade)</option>
                        <option value='70-80'>70-80 (7th grade)</option>
                        <option value='80-90'>80-90 (6th grade)</option>
                        <option value='90-100'>90-100 (5th grade)</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label
                            style={{ fontSize: "small", maxWidth: "fit-content" }}
                            htmlFor='length'
                          >
                            Make it:
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="Article Lab AI crafts complete HTML documents ranging from 300 to 2000 words, complete with SEO-optimized H1, H2, and H3 headers. A minimum of 400 words is generally considered effective for blog publishing, providing enough depth and detail for readers and search engines alike, while facilitating comprehensive topic coverage. Optimize your content structure for best SEO and reader engagement!"  />
                        </Col>
                      </Row>
                      <Col>
                        <Form.Select
                          size='sm'
                          id='length'
                          value={length.value}
                          onChange={length.onChange}
                          required
                        >
                          <option value=''>Select an Article Length</option>
                          <option value='300'>Tiny ~300 words</option>
                          <option value='400'>Minimalistic ~400 words</option>
                          <option value='600'>Medium ~600 words</option>
                          <option value='1200'>Good ~1200 words</option>
                          <option value='1600'>Awesome ~1600 words</option>
                          <option value='2000'>Super awesome ~2000 words</option>
                          {/*<option value="0">Optimal for subject</option>*/}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label style={{ fontSize: "small" }} htmlFor='includedKeywords'>
                            Including these keywords:
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="If you know which keywords you want to target, add them here. If left blank, our unique algorithm will step in, researching, generating, and ranking keywords based on your specified context, writing style, and point of view. This ensures your content is perfectly tuned for SEO, regardless of your prior keyword knowledge. Boost your visibility with smart keyword integration!"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                        <CreatableSelect
                            placeholder='Enter your keywords'
                            noOptionsMessage={({ inputValue: string }) => "Enter your keywords"}
                            isMulti
                            value={parseStringOptions(includedKeywords.value)}
                            onChange={includedKeywords.setValue}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                minHeight: "11.7rem",
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                                maxHeight: "11.7rem",
                                overflow: "auto",
                              }),
                              dropdownIndicator: (provided, state) => ({
                                display: "none",
                              }),
                              multiValueRemove: (provided, state) => ({
                                ...provided,
                                color: "black",
                                ":hover": {
                                  backgroundColor: "#ff8e5d",
                                },
                              }),
                              menuList: (provided, state) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            {contentCreationMode === "2" && (
              <>
                <Row>
                  <Col md={6}>
                    <Row className='justify-content-between'>
                      <Col>
                        <Form.Label
                          className='required'
                          style={{ fontSize: "small", alignSelf: "center" }}
                          htmlFor='includedKeywords'
                        >
                          Write an article about...
                        </Form.Label>
                      </Col>
                      <Col xs='auto'>
                        <CustomTooltip text="Prompt-free Creation: No need to start with a specific prompt. Just focus on the topic you want the Lab to write about. Let the magic happen!" />
                      </Col>
                    </Row>
                    <Form.Control
                      className="mb-3"
                      rows={3}
                      as='textarea'
                      value={articleContext.value}
                      onChange={articleContext.onChange}
                      required
                      isInvalid={articleContext.isInvalid}
                      placeholder='... My company, Inc. and its role in the AI revolution&#10;... developmental needs of monsters&#10;... kittens'
                    />
                    <div className='invalid-feedback'>Please include an outline</div>

                    <Form.Group className='mb-3'>
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label
                            className='required'
                            style={{ fontSize: "small" }}
                            htmlFor='writingStyle'
                          >
                            Using this writing style:
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="Feel free to define your desired tone and approach.
                          Be it friendly, academic, or investigative journalism, the Lab will emulate your unique style.
                          Unleash your creativity and craft articles that truly resonate with your voice!" />
                        </Col>
                      </Row>
                      <Col>
                        <Form.Control
                          size='sm'
                          type='text'
                          id='writingStyle'
                          value={writingStyle.value}
                          onChange={writingStyle.onChange}
                          isInvalid={writingStyle.isInvalid}
                          placeholder='journalistic, friendly, general, expository, persuasive...'
                          required
                        />
                        <div className='invalid-feedback'>Please include the writing style</div>
                      </Col>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label
                            className='required'
                            style={{ fontSize: "small" }}
                            htmlFor='persona'
                          >
                            From the point of view of:
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="Specify your desired perspective here.
                          Whether you want the article to sound like it's from you, an expert in your field, or someone your audience cares about, this information helps tailor the content to be as unique and impactful as possible. Express yourself through AI!" />
                        </Col>
                      </Row>
                      <Col>
                        <Form.Control
                          size='sm'
                          type='text'
                          id='persona'
                          value={persona.value}
                          onChange={persona.onChange}
                          isInvalid={persona.isInvalid}
                          placeholder='a journalist, an online marketeer, Peter Pan, a foodie, a teacher...'
                          required
                        />
                        <div className='invalid-feedback'>Please include the point of view</div>
                      </Col>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label style={{ fontSize: "small" }} htmlFor='audience'>
                            For an audience of:
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="Define your intended audience here. This helps shape the content's language, depth, and relevance. Whether you're targeting beginners, tech-savvy readers, or seasoned professionals, setting the audience helps deliver an article that resonates with your specific reader group. Shape your narrative for maximum impact!"  />
                        </Col>
                      </Row>
                      <Col>
                        <Form.Control
                          size='sm'
                          type='text'
                          id='audience'
                          value={audience.value}
                          onChange={audience.onChange}
                          placeholder='content creators, school moms, tycoons, gourmands, students...'
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label style={{ fontSize: "small" }} htmlFor='fleschScore'>
                            With this Flesch Score:
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="This readability score indicates how easy it is to understand a text. A higher score suggests simpler, more easily understood content, while a lower score indicates more complex language. By default, we set it at 60-70, a balance suitable for a wide audience, from high school students to professionals. Tailoring your Flesch Score helps align your content's complexity with your audience's comprehension level, enhancing reader engagement and satisfaction."  />
                        </Col>
                      </Row>
                      <Form.Select
                        size='sm'
                        id='fleschScore'
                        value={fleschScore.value}
                        onChange={fleschScore.onChange}
                        required
                      >
                        <option value='0-30'>0-30 (college graduate to professional)</option>
                        <option value='30-50'>30-50 (college)</option>
                        <option value='50-60'>50-60 (10th to 12th grade)</option>
                        <option value='60-70'>60-70 (8th & 9th grade)</option>
                        <option value='70-80'>70-80 (7th grade)</option>
                        <option value='80-90'>80-90 (6th grade)</option>
                        <option value='90-100'>90-100 (5th grade)</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Row>
                        <Col className='justify-content-between'>
                          <button
                              style={{fontSize: "small", marginBottom: "8px"}}
                              htmlFor="title"
                              onClick={() => regenerateTitle(title.value)}
                              className="text-button required"
                              disabled={!title.value}
                          >
                            {title.value ? "Optimize title" : "Create your own title"}
                          </button>

                          {loadingTitle && <Spinner size='sm' />}
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="Enter a few words related to your content, and the Lab will generate an SEO-friendly title.
                          Feel free to generate as many title ideas as you like until you find the perfect fit.
                          This feature ensures your article title is engaging and optimized for search engines, boosting visibility and attracting more readers!" />
                        </Col>
                      </Row>
                      <Form.Control
                          value={title.value}
                          onChange={title.onChange}
                          type='text'
                          id='title'
                          size='sm'
                          required
                          placeholder='The Ultimate Leader: Why My Company Inc Reigns Supreme...'
                          isInvalid={title.isInvalid}
                      />
                      <div className='invalid-feedback'>Please include a title</div>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label
                            style={{ fontSize: "small", maxWidth: "fit-content" }}
                            htmlFor='length'
                          >
                            Make it:
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="Article Lab AI crafts complete HTML documents ranging from 300 to 2000 words, complete with SEO-optimized H1, H2, and H3 headers. A minimum of 400 words is generally considered effective for blog publishing, providing enough depth and detail for readers and search engines alike, while facilitating comprehensive topic coverage. Optimize your content structure for best SEO and reader engagement!"  />
                        </Col>
                      </Row>
                      <Col>
                        <Form.Select
                          size='sm'
                          id='length'
                          value={length.value}
                          onChange={length.onChange}
                          required
                        >
                          <option value=''>Select an Article Length</option>
                          <option value='300'>Tiny ~300 words</option>
                          <option value='400'>Minimalistic ~400 words</option>
                          <option value='600'>Medium ~600 words</option>
                          <option value='1200'>Good ~1200 words</option>
                          <option value='1600'>Awesome ~1600 words</option>
                          <option value='2000'>Super awesome ~2000 words</option>
                          {/*<option value="0">Optimal for subject</option>*/}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Row className='justify-content-between'>
                        <Col>
                          <Form.Label style={{ fontSize: "small" }} htmlFor='includedKeywords'>
                            Including these keywords:
                          </Form.Label>
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="If you know which keywords you want to target, add them here. If left blank, our unique algorithm will step in, researching, generating, and ranking keywords based on your specified context, writing style, and point of view. This ensures your content is perfectly tuned for SEO, regardless of your prior keyword knowledge. Boost your visibility with smart keyword integration!"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <CreatableSelect
                            placeholder='Enter your keywords'
                            noOptionsMessage={({ inputValue: string }) => "Enter your keywords"}
                            isMulti
                            value={parseStringOptions(includedKeywords.value)}
                            onChange={includedKeywords.setValue}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                minHeight: "5rem",
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                                maxHeight: "5rem",
                                overflow: "auto",
                              }),
                              dropdownIndicator: (provided, state) => ({
                                display: "none",
                              }),
                              multiValueRemove: (provided, state) => ({
                                ...provided,
                                color: "black",
                                ":hover": {
                                  backgroundColor: "#ff8e5d",
                                },
                              }),
                              menuList: (provided, state) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Row>
                        <Col className='justify-content-between'>
                          <button
                            style={{ fontSize: "small", marginBottom: "8px" }}
                            htmlFor='title'
                            onClick={() =>
                              getKeywords(
                                articleContext.value,
                                includedKeywords.value,
                                setOptimizedKeywords
                              )
                            }
                            disabled={!articleContext.value && !includedKeywords.value}
                            className='text-button'
                          >
                            Generate optimized keywords
                          </button>
                          {loadingKeywords && <Spinner size='sm' />}
                        </Col>
                        <Col xs='auto'>
                          <CustomTooltip text="This interactive tool lets you research and get just the right amount of keywords based on your article's context, keywords, and length.
                          (TBH, either is fine!).
                          Generate a variety of keyword ideas, and our exclusive algorithm will present you with the best possible options.
                          This ensures your content is not only reader-friendly but also finely tuned for SEO.
                          Enhance your visibility with precision-targeted keywords!"/>
                        </Col>
                      </Row>
                      <Select
                        isDisabled={!optimizedKeywords}
                        placeholder=''
                        noOptionsMessage=''
                        menuIsOpen={false}
                        isMulti
                        value={parseStringOptions(optimizedKeywords)}
                        onChange={setOptimizedKeywords}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            minHeight: "7rem",
                          }),
                          valueContainer: (provided, state) => ({
                            ...provided,
                            maxHeight: "7rem",
                            overflow: "auto",
                          }),
                          dropdownIndicator: (provided, state) => ({
                            display: "none",
                          }),
                          multiValueRemove: (provided, state) => ({
                            ...provided,
                            color: "black",
                            ":hover": {
                              backgroundColor: "#ff8e5d",
                            },
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            <Row className='justify-content-end'>
              <Button
                onClick={handleSubmit}
                className='Submit-button mx-2'
                disabled={disableSubmit}
              >
                Generate an article
              </Button>
            </Row>
            <div className='marketing-message'>
              {contentCreationMode === "0" && (
                <div className='marketing-header'>
                  <Row>
                    <Col>
                      <h3>What goes in the brew</h3>
                      Enter your topic and watch as the Lab brews a 400-word marketing-focused
                      article, chock-full of current information, presented in an SEO-optimized
                      format. As a bonus, enjoy an AI-created relevant image to complement your
                      content.
                    </Col>
                    <Col>
                      <h3>Behind closed doors...</h3>
                      We leverage real-time topic research and news updates, combined with thorough
                      keyword research and optimization. All of this is geared towards a general
                      audience, ensuring high readability and shareability.
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3>Post-Creation Magic</h3>
                      Once your article is ready, feel free to finesse it with our full-featured
                      editor, including grammar and spell checking.
                      <br />
                      Ready to share with the world? Simply copy and paste your polished piece to
                      any blog.
                      <p>
                        Effortless content creation and publishing are now at your fingertips.
                        Experience the power of AI-enabled content creation!
                      </p>
                    </Col>
                  </Row>
                </div>
            )}</div>
          </Col>
        ) : (
          <Col lg={7} className='creation-page'>
            <Row
              className='justify-content-lg-center creating-header pt-5 mb-5'
              style={{ textWrap: "nowrap" }}
            >
              Creation in progress
            </Row>
            {/*<Row className='justify-content-center align-items-center py-4'>*/}
            {/*  <Spinner*/}
            {/*    animation='grow'*/}
            {/*    variant='primary'*/}
            {/*    style={{ width: "9rem", height: "9rem" }}*/}
            {/*  ></Spinner>*/}
            {/*  <Spinner*/}
            {/*    animation='grow'*/}
            {/*    style={{ width: "10rem", height: "10rem", color: "#E17445" }}*/}
            {/*  ></Spinner>*/}
            {/*  <Spinner*/}
            {/*    animation='grow'*/}
            {/*    variant='primary'*/}
            {/*    style={{ width: "9rem", height: "9rem" }}*/}
            {/*  ></Spinner>*/}
            {/*</Row>*/}

            <Row className='justify-content-lg-center mt-4'>
              {/*error-message*/}
              <div>{progressTextArray[progressTextIndex]}</div>

              {/*{error && <div style={{ width: "fit-content" }}>{error}</div>}*/}
            </Row>
            <Row className='justify-content-lg-center'>
              <Col>
                <ProgressBar min={0} striped animated now={progress} />
              </Col>
            </Row>
           
            <Row className='justify-content-lg-center' style={{marginTop:60, minWidth:360, minHeight:80}}>
              <table style={{ border: '1px solid white' }}>
                <tr><td style={{ padding: 20 }}>
                  <p>
                    Hang on! It takes some time to create quality content.
                    Feel free to head to <Link class="httpLink" to="/article">My lab</Link>, and start another article. We will notify you when your article is done and saved in <Link class="httpLink" to="/documents">My work</Link>.
                  </p>
                </td></tr>
              </table>
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
};
