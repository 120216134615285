import React from "react";
import cloneDeep from "lodash.clonedeep";
import throttle from "lodash.throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../App.css";
import localeInfo from "./pagination-locale.js";


const tableHead = {
    articleTitle: "Article title",
    timestamp: "Modified"
};

const MyTable = ({ allData, onRowClick }) => {
    const countPerPage = 12;
    const [value, setValue] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);
    const [collection, setCollection] = React.useState([]);

    React.useEffect(() => {
        updatePage(1);
    }, [allData]); // Rerun when allData changes

    React.useEffect(() => {
        const searchData = throttle(val => {
            const query = val.toLowerCase();
            setCurrentPage(1);
            const data = cloneDeep(
                allData
                    .filter(item => item.message && item.message.articleTitle
                        ? item.message.articleTitle.toLowerCase().indexOf(query) > -1
                        : false)
                    .slice(0, countPerPage)
            );
            setCollection(data);
        }, 400);

        if (!value) {
            updatePage(1);
        } else {
            searchData(value);
        }
    }, [value, allData]); // Rerun when value or allData changes

    const updatePage = p => {
        setCurrentPage(p);
        const to = countPerPage * p;
        const from = to - countPerPage;
        setCollection(cloneDeep(allData.slice(from, to)));
    };

    const tableRows = rowData => {
        const { key, index } = rowData;
        const tableCell = Object.keys(tableHead);
        const columnData = tableCell.map((keyD, i) => {
            // Check if keyD is 'articleTitle', if so, use 'message.articleTitle' from the data
            const value = keyD === 'articleTitle' ? key.message[keyD] : key[keyD];
            return <td className="table-item" key={i}>{value}</td>;
        });

        return <tr key={index} onClick={() => onRowClick(key)}>{columnData}</tr>;
    };

    const tableData = () => {
        return collection.map((key, index) => tableRows({ key, index }));
    };

    const headRow = () => {
        return Object.values(tableHead).map((title, index) => (
            <td key={index}>{title}</td>
        ));
    };

    return (
        <>
            <div className="mb-4">
                <input className="rounded rounded-3"
                    placeholder="Find document by title"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
            </div>
            <table className="table table-hover table-bordered table-light table-striped rounded rounded-3 overflow-hidden">
                <thead className="table-light">
                <tr style={{fontWeight: "bold"}}>{headRow()}</tr>
                </thead>
                <tbody >{tableData()}</tbody>
            </table>
            <Pagination
                pageSize={countPerPage}
                onChange={updatePage}
                current={currentPage}
                total={allData.length}
                locale={localeInfo}
            />
        </>
    );
};
export default MyTable;
