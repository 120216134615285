import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GrammarlyEditorPlugin, Grammarly } from "@grammarly/editor-sdk-react";
import { Editor } from "@tinymce/tinymce-react";
import MyTable from "./helpers/MyTable.js";
import { Row, Container, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  ViberShareButton,
  WhatsappShareButton,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  ViberIcon,
  WeiboShareButton,
  WeiboIcon,
  PocketIcon,
  InstapaperIcon,
  LivejournalShareButton,
  InstapaperShareButton,
} from "react-share";

const ShareButtons = ({ shareUrl }) => {
  return (
    <>
      <EmailShareButton url={`https://www.articlelab.ai/${shareUrl}`}>
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
      <FacebookShareButton url={`https://www.articlelab.ai/${shareUrl}`}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <TwitterShareButton url={`https://www.articlelab.ai/${shareUrl}`}>
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <TelegramShareButton url={`https://www.articlelab.ai/${shareUrl}`}>
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton>
      <WhatsappShareButton url={`https://www.articlelab.ai/${shareUrl}`}>
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      <RedditShareButton url={`https://www.articlelab.ai/${shareUrl}`}>
        <RedditIcon size={32} round={true} />
      </RedditShareButton>
      <TumblrShareButton url={`https://www.articlelab.ai/${shareUrl}`}>
        <TumblrIcon size={32} round={true} />
      </TumblrShareButton>
      <LivejournalShareButton url={`https://www.articlelab.ai/${shareUrl}`}>
        <LivejournalIcon size={32} round={true} />
      </LivejournalShareButton>
      <ViberShareButton url={`https://www.articlelab.ai/${shareUrl}`}>
        <ViberIcon size={32} round={true} />
      </ViberShareButton>
      <WeiboShareButton url={`https://www.articlelab.ai/${shareUrl}`}>
        <WeiboIcon size={32} round={true} />
      </WeiboShareButton>
      <PocketShareButton url={`https://www.articlelab.ai/${shareUrl}`}>
        <PocketIcon size={32} round={true} />
      </PocketShareButton>
      <InstapaperShareButton url={`https://www.articlelab.ai/${shareUrl}`}>
        <InstapaperIcon size={32} round={true} />
      </InstapaperShareButton>
    </>
  );
};

export const Documents = () => {
  const { user } = useAuth0();
  const [files, setFiles] = useState([]);
  const userId = user?.sub?.split("|")[1];
  const [fileOpen, setFileOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState([]);
  const tinyApiKey = process.env.REACT_APP_TINY_API_KEY;
  const editorRef = useRef(null);
  const editorAnchorRef = useRef(null);
  const navigate = useNavigate();

  const shareUrl = `download-file/${userId}/${currentFile.fileName}`;
  useEffect(() => {
    if (fileOpen) {
      window.setTimeout(() => {
        if (editorRef.current && editorRef.current.editor) {
          editorRef.current.editor.focus();
        }
        if (editorAnchorRef.current) {
          editorAnchorRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, [fileOpen]);

  useEffect(() => {
    fetchUserFiles();
  }, [userId]);
  const fetchUserFiles = async () => {
    try {
      const response = await fetch(`/list-files/${userId}`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        data
          .sort((a, b) => {
            return new Date(b.timestamp) - new Date(a.timestamp);
          })
          .forEach((file) => {
            file.timestamp = new Date(file.timestamp).toLocaleString("en-US");
          });
        if (data.length === 0) {
          data.push({
            timestamp: "",
            message: {
              articleTitle: "No articles yet. Head to My lab to start writing!",
              fileName: null,
            },
          });
        }
        setFiles(data);
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error("Error fetching user files:", error, userId);
    }
  };

  async function loadFile(userId, fileName) {
    const url = `/get_doc_content/${userId}/${fileName}`;
    if (fileName === null) {
      navigate("/article");
      return;
    }
    try {
      const response = await fetch(url, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();
        setCurrentFile(data.fileContent[0].message);
        currentFileRef.current = data.fileContent[0].message;
        setFileOpen(true);
        //checking if article is array or string. placeholder until they're all arrays
        if (Array.isArray(data.fileContent[0]?.message.article)) {
          data.fileContent[0].message.article = [data.fileContent[0]?.message.article]
            .map((element) => `<div>${element}</div>`)
            .join("");
        }
        setCurrentFile(data.fileContent[0].message);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.error("Error fetching user files:", error, userId);
    }
    // const url = `uploads/${userId}/${fileName}`;
    //  window.open(url, '_blank');
  }
  const currentFileRef = useRef();

  const save = async () => {
    if (editorRef.current) {
      const article = editorRef.current.getContent();
      const fileName = currentFileRef.current.fileName;
      const articleTitle = currentFileRef.current.articleTitle;
      // const article = editorRef.current.getContent();
      // const fileName = currentFile.fileName;
      // const articleTitle = currentFile.articleTitle;
      const url = `/save_doc_content/${userId}/${fileName}`;
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ articleTitle, article }),
        });
        if (response.ok) {
          const data = await response.json();
          setCurrentFile((prevState) => ({ ...prevState, articleTitle: data.articleTitle }));
          fetchUserFiles();
        }
      } catch (error) {
        console.error("Error fetching user files:", error, userId);
      }
    }
  };

  const handleRowClick = (file) => {
    loadFile(userId, file.message.fileName);
  };

  return (
    <Container className='justify-content-center mb-5' style={{ maxWidth: 1080 }}>
      {fileOpen && (
        <>
          <div ref={editorAnchorRef}></div>
          <Grammarly>
            <GrammarlyEditorPlugin clientId='client_LNx21MHCLEcqpA1QqdQVKh'>
              <div>
                <Row className='my-3'>
                  <h1 className='Generated-title'>{currentFile.articleTitle}</h1>
                </Row>
                <Row className='justify-content-end mb-2'>
                  {/* <Col xs={"auto"}><button className="text-button">Publish</button></Col> */}
                  <Col xs={"auto"}>
                    <a fileName={`${"asd"}.html`} className='text-button' download href={shareUrl}>
                      Download
                    </a>
                  </Col>
                </Row>
                <Editor
                  apiKey={tinyApiKey}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={currentFile.article}
                  init={{
                    height: 700,
                    plugins: [
                      "image",
                      "save",
                      "autosave",
                      "link",
                      "visualblocks",
                      "wordcount",
                      "lists",
                    ],
                    toolbar:
                      "save | undo redo | blocks image link | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat wordcount",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    menu: {
                      file: { title: "File", items: "print" },
                    },
                    branding: false,
                    save_onsavecallback: () => {
                      save(currentFile.fileName);
                    },
                    autosave_interval: "5s",
                    relative_urls: false,
                    remove_script_host: false,
                  }}
                ></Editor>
              </div>
            </GrammarlyEditorPlugin>
          </Grammarly>
          <Row
            style={{ paddingRight: "calc(var(--bs-gutter-x)*.5)" }}
            className='justify-content-end row-cols-auto mt-3 mb-5'
            id='share-buttons'
          >
            <ShareButtons shareUrl={shareUrl} />
          </Row>
        </>
      )}
      <MyTable
        columns={[
          { title: "Title", field: "message.articleTitle" },
          { title: "Date", field: "message.date" },
          { title: "File Name", field: "message.fileName" },
        ]}
        allData={files}
        onRowClick={handleRowClick}
      />
    </Container>
  );
};
