import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Loading } from "./Loading.js";
import useAuthentication from "../hooks/useAuthentication.js";
import { ProductDisplay } from "./index.js";
import { useNavigate } from "react-router";

export const CustomerOnlyRoute = ({ component, ...args }) => {
  const { user, status, loading } = useAuthentication();
  const navigate = useNavigate();
  const Component = withAuthenticationRequired(component, {
    ...args,
    onRedirecting: () => <Loading />,
  });
  if (user && !loading && status.active) {
    return <Component />;
  } else if (loading) {
    return <Loading />;
  } else {
    navigate("/pricing");
    return;
  }
};
