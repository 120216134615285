import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  Root,
  Home,
  Personas,
  ArticleCreation,
  Contact,
  About,
  Privacy,
  Tos,
  Error,
  Faq,
  ProductDisplay,
  Documents,
  MyAccount,
  Loading,
  PostSignup,
  Features
} from "./components/index.js";
import { ProtectedRoute } from "./components/ProtectedRoute.js";
import { CustomerOnlyRoute } from "./components/CustomerOnlyRoute.js";
import { Auth0ProviderWithNavigate } from "./Auth0ProviderWithNavigate.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Auth0ProviderWithNavigate>
        <Root />
      </Auth0ProviderWithNavigate>
    ),
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "personas",
        element: <ProtectedRoute component={Personas} />,
      },
      {
        path: "article",
        element: <CustomerOnlyRoute component={ArticleCreation} />,
      },
      {
        path: "documents",
        element: <ProtectedRoute component={Documents} />,
      },
      {
        path: "account",
        element: <ProtectedRoute component={MyAccount} />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },
      {
        path: "tos",
        element: <Tos />,
      },
      {
        path: "faq",
        element: <Faq />,
      },
      {
        path: "pricing",
        element: <ProductDisplay />,
      },
      {
        path: "callback",
        element: <Loading />,
      },
      {
        path: "post-signup",
        element: <PostSignup />,
      },
      {
        path: "features",
        element: <Features />,
      }
    ],
  },
]);

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
