import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

export const Privacy = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#F5F4F6";
    document.body.style.color = "black";

    return () => {
      document.body.style.backgroundColor = "#3B3255";
      document.body.style.color = "white";
    };
  }, []);
  return (
    <Container
      style={{ position: "relative", background: "#f5f4f6", zIndex: 1, top: 0 }}
    >
      <Row className='justify-content-center'>
        <Col lg={8}>
          <div className='pages-title-div'>
            <h1 className='pages-title'>Privacy Policy</h1>
            <div className={"pages-content"}>
              <p>Effective Date: May 21, 2023</p>
              <p>
                At ArticleLab.ai, we are committed to protecting the privacy and security of our
                users' personal information. This Privacy Policy outlines how we collect, use, and
                disclose information when you visit our website www.articlelab.ai (referred to as
                the "Site"). By accessing or using our Site, you agree to the terms and practices
                described in this Privacy Policy.
              </p>
              <h2>1. Information We Collect</h2>
              <p>
                We do not store any personally identifiable information (PII) about our users unless
                explicitly provided by you. PII refers to information that can be used to identify
                an individual, such as your name, email address, or contact information. We respect
                your privacy and strive to collect only the minimum necessary information to provide
                you with the services and features of our Site.
              </p>
              <h2>2. Cookies and Tracking Technologies</h2>
              <p>
                We may use cookies and similar tracking technologies to enhance your browsing
                experience on our Site. Cookies are small text files that are stored on your device
                when you visit certain websites. These files contain anonymous information that
                helps us improve our Site's functionality, analyze trends, and administer the Site.
                You can manage your cookie preferences through your browser settings.
              </p>
              <h2>3. Third-Party Service Providers</h2>
              <p>
                We may engage third-party service providers to assist us in providing various
                services on our Site. These service providers may have access to certain information
                collected through the Site but are strictly limited to use it solely on our behalf
                and as instructed by us. We partner with vendors such as Auth0 and Stripe for
                authentication and payments. We encourage you to review the privacy policies of
                these vendors to understand how they handle your information.
              </p>
              <h2>4. Information Security</h2>
              <p>
                We have implemented appropriate security measures to protect your information from
                unauthorized access, loss, misuse, or alteration. However, please note that no data
                transmission over the internet or electronic storage method is completely secure.
                Therefore, while we strive to protect your information, we cannot guarantee its
                absolute security.
              </p>
              <h2>5. Links to Third-Party Websites</h2>
              <p>
                Our Site may contain links to third-party websites or services that are not owned or
                controlled by us. We are not responsible for the privacy practices or content of
                these third-party sites. We encourage you to review the privacy policies of these
                websites before providing any personal information to them.
              </p>
              <h2>6. Children's Privacy</h2>
              <p>
                Our Site is not intended for use by individuals under the age of 16. We do not
                knowingly collect personal information from children. If you believe that we may
                have collected personal information from a child under the age of 16, please contact
                us immediately, and we will take appropriate steps to remove the information from
                our records.
              </p>
              <h2>7. Changes to the Privacy Policy</h2>
              <p>
                We reserve the right to modify or update this Privacy Policy at any time. Any
                changes made to the Privacy Policy will be effective immediately upon posting. We
                encourage you to review this Privacy Policy periodically to stay informed about our
                information practices.
              </p>
              <h2>8. Contact Us</h2>
              <p>
                If you have any questions, concerns, or suggestions regarding this Privacy Policy,
                please contact us at{" "}
                <a href='mailto:support@articlelab.ai'>support@articlelab.ai</a>.
              </p>
              <p>
                By using our Site, you acknowledge that you have read and understood this Privacy
                Policy and agree to be bound by its terms and conditions.
              </p>
              <p>Last updated: May 23, 2023</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
