import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

export const Contact = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#F5F4F6";
    document.body.style.color = "black";

    return () => {
      document.body.style.backgroundColor = "#3B3255";
      document.body.style.color = "white";
    };
  }, []);

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.src = "//js.hsforms.net/forms/embed/v2.js";
    script1.async = true;

    script1.onload = () => {
      const script2 = document.createElement("script");
      script2.innerHTML = `
        hbspt.forms.create({
          region: "na1",
          portalId: "39736115",
          formId: "57bbff4f-3b9d-4fc0-a958-fc00f2ba4caf",
          target: "#hsFormWrapper"
        });
      `;
      document.body.appendChild(script2);
    };

    document.body.appendChild(script1);

    return () => {
      document.body.removeChild(script1);
    };
  }, []);

  return (
    <div
      className='contact-page bg-custom'
      style={{
        minHeight: "100vh",
      }}
    >
      <Row className='justify-content-center' style={{  }}>
        <Col lg={4} className="mb-5" style={{textAlign: 'center'}}>
          <div className='pages-title-div'>
            <h1 className='pages-title'>Contact Us</h1>
          </div>
            <h2>We'd love to hear from you</h2>
        </Col>
        <Row className='justify-content-center pages-content'>
          {/*<div className="col-6"></div>*/}
          <Col>
            <p className='text-left'>
              We are always looking for feedback and suggestions to make our site better. Your input
              helps us create a better experience for everyone.
            </p>
            <p>
              We also welcome any questions or comments you may have about our service and ideas for
              how we can improve it. If you want to share your thoughts with us directly, please do
              not hesitate to reach out. We would love to hear from you!
            </p>
            <p> The Article Lab Team.</p>
          </Col>
          <Col>
            <div id='hsFormWrapper'></div>
          </Col>
          {/*<div className="col-6"></div>*/}
        </Row>
      </Row>
    </div>
  );
};
