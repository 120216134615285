import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { LinkWithHover } from "./LinkWithHover.js";

export const NavBar = ({ show, setShow }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [navClass, setNavClass] = useState("flex-grow-1 d-flex justify-content-center d-lg-flex")
  useEffect(() => {
    if (show) setNavClass("navbar-vertical");
    setTimeout(() => {
      setNavClass(show ? "navbar-vertical" : "flex-grow-1 d-flex justify-content-center d-lg-flex");
    }, 1000);
  }, [show]);
  

  return (
    <Nav
      as='nav'
      defaultActiveKey='/'
      className={navClass}
      onClick={()=>setShow(false)}
    >
      {!isAuthenticated ? (
        <>
          <LinkWithHover link='/about' text='About us' />
          <LinkWithHover link='/contact' text='Contact us' />
          <LinkWithHover link='/pricing' text='Pricing' />
          <LinkWithHover link='/features' text='Features' />
          <LinkWithHover link='/privacy' text='Privacy policy' />
          <LinkWithHover link='https://blog.articlelab.ai' text='Blog' external/>
          {show && (<button onClick={() =>
        loginWithRedirect({
          appState: { target: "/article" },
        })
      } style={{ background: 'none', border: 'none' }} className="nav-link d-block d-md-none">Log in</button>)}
        </>
      ) : (
        <>
          <LinkWithHover link='/article' text='My lab' />

          {/*<Nav.Link as={Link} to={`personas`}>*/}
          {/*  Personas*/}
          {/*</Nav.Link>*/}
          <LinkWithHover link='/documents' text='My work' />
          {/* {isArticleLoading && <Spinner animation='border' size='sm'/>} */}
          <LinkWithHover link='/account' text='My account' />
        </>
      )}
    </Nav>
  );
};
