import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { Button, Col, Offcanvas, Row, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavBar } from "./NavBar.js";

const Profile = ({ user, isAuthenticated, isLoading }) => {
  const navigate = useNavigate();
  if (isLoading) {
    return (
      <>
        <Spinner animation='border' variant='light' />
      </>
    );
  }

  return (
    isAuthenticated && (
      <div className='profile'>
        <img
          title={user.email}
          src={user.picture}
          alt={user.name}
          onClick={() => navigate("/account")}
        />
      </div>
    )
  );
};
const SignupButton = ({ pathname, loginWithRedirect, isAuthenticated, logout }) => {
  const navigate = useNavigate();

  if (isAuthenticated) {
    return (
      <Button
        variant='outline-primary'
        className='login-button'
        onClick={() => logout({ logOutParams: { returnTo: window.location.pathname } })}
      >
        Sign Out
      </Button>
    );
  }

  return (
    <>
      <Button
        variant='primary'
        className='signup-button'
        onClick={() => navigate("/pricing")}
        style={{ visibility: pathname === "/pricing" ? "hidden" : "visible" }}
      >
        Try for free!
      </Button>
      <Button
        variant='outline-primary'
        className='login-button d-none d-md-block d-s-none'
        onClick={() =>
          loginWithRedirect({
            appState: { target: "/article" },
          })
        }
      >
        Login
      </Button>
    </>
  );
};
const Header = ({ timeTaken }) => {
  const [show, setShow] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();
  const { pathname } = useLocation();

  return (
    <Row as='header' style={{ justifyContent: "right" }} className='App-header align-items-center'>
      <Col id='header-logo-wrapper' style={{ maxWidth: "100%" }}>
        <Link
          className='pt-2 w-fc'
          to={isAuthenticated ? `/article` : `/`}
          onClick={() => window.scrollTo(0, 0)}
        >
          <img id='header-logo' src='/images/logo-article-lab-ai.svg' alt='logo' className="d-none d-sm-block"></img>
          <img id='header-logo' src='/images/logo-a.svg' alt='logo' className="d-block d-sm-none"></img>
        </Link>
      </Col>
      <Offcanvas
        style={{ height: "fit-content" }}
        show={show}
        onHide={() => setShow(false)}
        className='text-bg-dark'
        placement='top'
      >
        <Offcanvas.Header closeButton closeVariant='white' responsive='lg'></Offcanvas.Header>
        <Offcanvas.Body>
          <NavBar show={show} setShow={setShow} />
        </Offcanvas.Body>
      </Offcanvas>
      <Button
        className='d-lg-none'
        id='hamburger-button'
        onClick={() => setShow((prev) => !prev)}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          className='bi bi-list'
          viewBox='0 0 16 16'
        >
          <path
            fillRule='evenodd'
            d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'
          />
        </svg>
      </Button>

      <Col className='d-none d-lg-block d-xs-none' style={{ flexGrow: 2 }}>
        <NavBar show={show} setShow={setShow} />
      </Col>
      {((pathname === "/pricing" && user) || pathname !== "/pricing") && (
        <Col
          className='d-flex justify-content-end'
          style={{ padding: 0, marginRight: 0, maxWidth: "fit-content" }}
        >
          <div className=' profile-box'>
            <Profile user={user} isAuthenticated={isAuthenticated} isLoading={isLoading} />
            <SignupButton
              pathname={pathname}
              loginWithRedirect={loginWithRedirect}
              isAuthenticated={isAuthenticated}
              logout={logout}
            />
          </div>
        </Col>
      )}
    </Row>
  );
};
// {timeTaken > 0 && (
//     <div className="Time-taken">
//         And you waited{" "}
//         {timeTaken > 60 ? `${(timeTaken / 60).toFixed(2)} minutes` : `${timeTaken} seconds`} for
//         this?
//     </div>
// )}

export default Header;
