import React, { useState } from "react";
import { Row, Container } from "react-bootstrap";
import Header from "./Header.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { Outlet } from "react-router";
import { Footer } from "./Footer.js";

const Root = () => {
  const [timeTaken, setTimeTaken] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);

  return (
    <Container fluid style={{ width: "100%" }}>
      <Row className='App-header fixed-top'>
        <Header timeTaken={timeTaken} />
      </Row>
      <Container fluid className='main-content'>
        <Outlet context={{ timeTaken, setTimeTaken, uploadedFile, setUploadedFile }} />
      </Container>
      <Footer />
    </Container>
  );
};

export { Root };
