import { Nav } from "react-bootstrap";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

export const LinkWithHover = ({ link, text, external }) => {
  const { pathname } = useLocation();
  if (external) {
    return (
      <a className='nav-link' href={link} target='_blank' rel='noreferrer'>
        {text}
      </a>
    );
  }
  return (
    <Nav.Link
      className={pathname === `${link}` ? "current-route" : ""}
      as={Link}
      to={`${link}`}
      onClick={() => window.scrollTo(0, 0)}
    >
      {text}
    </Nav.Link>
  );
};
