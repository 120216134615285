import React from "react";
import { TosContent } from "./TosContent.js";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

export const Tos = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#F5F4F6";
    document.body.style.color = "black";

    return () => {
      document.body.style.backgroundColor = "#3B3255";
      document.body.style.color = "white";
    };
  }, []);
  return (
    <Container
      style={{ position: "relative", background: "#f5f4f6", zIndex: 1, top: 0 }}
    >
      <Row className='row justify-content-center'>
        <Col className='col-lg-8'>
          <div className='pages-title-div'>
            <h1 className='pages-title'>{TosContent.title}</h1>
            <div className={"pages-content"}>
              <p>Last Updated: {TosContent.lastUpdated}</p>
              <p>{TosContent.welcome}</p>

              {TosContent.sections.map((section, index) => (
                <React.Fragment key={index}>
                  <h2>
                    {index + 1}. {section.Title}
                  </h2>
                  <p>{section.Content}</p>
                </React.Fragment>
              ))}

              <h2>{TosContent.contact.title}</h2>
              <p>{TosContent.contact.content}</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
