import { useEffect } from "react";
import { Loading } from "./Loading.js";
import { useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
export const PostSignup = () => {
  const navigate = useNavigate();
  const { user, isLoading } = useAuth0();
  const userPipe = user?.sub?.split("|")[1];

  useEffect(() => {
    const local = window.localStorage.getItem("chosen_price");

    if (user && local) {
      const subscribe = () => {
        const response = fetch(`/check-customer/${userPipe}/${local}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
          // ,
          // body: JSON.stringify({
          //   price: local
          // }),
        });
        window.localStorage.removeItem("chosen_price");
        navigate("/article");

      };
      subscribe();
    }
    else if (user && !local) {navigate("/pricing")}
    
    // setTimeout(() => {
    //   navigate("/pricing")
    // }, 5000);
  }, [user, isLoading]);

  return (
      <>
        <h3>Please wait while we create your account</h3>
        <Loading />
      </>
  );
};





