import React, { useEffect, useState } from "react";

import "../App.css";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthentication from "../hooks/useAuthentication.js";
import { Loading } from "./Loading.js";
import { useNavigate } from "react-router";

export const ProductDisplay = () => {
  const { user, loginWithRedirect } = useAuth0();
  const { status, loading } = useAuthentication();
  const [yearly, setYearly] = useState(false);
  const userPipe = user?.sub?.split("|")[1];
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  const fetchPrices = async () => {
    const response = await fetch("/get-prices", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  useEffect(() => {
    fetchPrices().then((data) => {
      data.sort((a, b) => a.featured - b.featured);
      setProductData(data);
      setPageLoading(false);
    });
  }, []);

  const priceCards = [
    {
      title: productData[0]?.name,
      text: productData[0]?.description,
      price: `$${
        yearly
          ? productData[0]?.prices.find((item) => item.yearly)?.unit_amount / 100
          : productData[0]?.prices.find((item) => !item.yearly)?.unit_amount / 100
      }.00`,
      features: productData[0]?.features,
      id: yearly
        ? productData[0]?.prices.find((item) => item.yearly)?.id
        : productData[0]?.prices.find((item) => !item.yearly)?.id,
    },
    {
      title: productData[1]?.name,
      text: productData[1]?.description,
      price: `$${
        yearly
          ? productData[1]?.prices.find((item) => item.yearly)?.unit_amount / 100
          : productData[1]?.prices.find((item) => !item.yearly)?.unit_amount / 100
      }.00`,
      features: productData[1]?.features,
      id: yearly
        ? productData[1]?.prices.find((item) => item.yearly)?.id
        : productData[1]?.prices.find((item) => !item.yearly)?.id,
    },
    {
      title: "Teams",
      text: "For content creation organizations",
      price: "Custom pricing",
      features: ["Multiple CMSs", "Research Lab", "Image Lab"],
      id: "teams",
    },
  ];

  const handleClick = async (id, event) => {
    if (status?.type === "canceled") {
      return;
    } else event.preventDefault();
    if (id === "teams") {
      navigate("/contact");
    } else if (!user) {
      window.localStorage.setItem("chosen_price", id);
      loginWithRedirect({
        appState: {
          target: "/post-signup",
        },
        authorizationParams: { screen_hint: "signup" },
      });
    } else {
      const response = await fetch("/create-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: userPipe,
          price: id,
        }),
      });
      if (response.status === 403) {
        console.error("res", await response.json());
      } else if (response.ok) {
        window.location.href = "/article";
      }
    }
  };
  let ctaText = "";
  switch (status?.type) {
    case "trial":
      ctaText = `Enjoy your free trial for 10 articles or until ${new Date(
        status?.endDate * 1000
      ).toLocaleDateString()}!`;
      break;
    case "paying":
      ctaText = "Feeling like an upgrade?";
      break;
    case "paused":
      ctaText = "We hope we enjoyed your free trial!";
      break;
    case "canceled":
      ctaText = "Don’t miss out! Restart your subscription today!";
      break;
    default:
      ctaText = "Start your 7 day, 10 article free trial with no credit card!";
  }
  return loading || pageLoading ? (
    <Loading />
  ) : (
    <>
      <Row className='d-flex justify-content-center mb-2'>
        <Col>
          <h1 className='mt-5 mb-4' style={{ fontWeight: "bold", textAlign: "center", fontSize: "3rem" }}>Choose your plan</h1>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center mb-5'>
        <Col>
          <h5 style={{ textAlign: "center" }}>{ctaText}</h5>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-center'>
          <ButtonGroup>
            <Button onClick={() => setYearly(false)} className={`${yearly ? "" : "active"}`}>
              Monthly
            </Button>
            <Button onClick={() => setYearly(true)} className={`${yearly ? "active" : ""}`}>
              Yearly
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row className='mt-5 justify-content-center pricing-cards'>
        {priceCards.map((card, index) => (
          <Col className='d-flex col-12 col-lg-auto p-0 justify-content-center'>
            <Card
              key={index}
              text='dark'
              style={{ maxWidth: "300px", minHeight: "26rem", padding: 0, marginBottom: "2rem" }}
              className={`${index !== 1 ? "" : "popular-card"}`}
            >
              {index === 1 && <div className='popular'>Most Popular</div>}
              <Card.Body>
                <Card.Title
                  className='mt-4'
                  style={{ fontWeight: "Bold", fontSize: "1.8rem", textAlign: "center" }}
                  as='h5'
                >
                  {card.title}
                </Card.Title>
                <Card.Text
                  className='card-description mt-4'
                  style={{ height: "4rem", textAlign: "center" }}
                >
                  {card.text}
                </Card.Text>
                <Card.Text>
                  <div style={{ minHeight: "66px" }} className='d-grid justify-content-center'>
                    <span className='card-price'>{card.price}</span>
                    {card.title !== "Teams" && (
                      <span className='card-price-text'>
                        {yearly ? "two months free!" : "per month"}
                      </span>
                    )}
                  </div>
                  <div className='d-flex justify-content-center mt-3'>
                    {card.title !== "Teams" &&
                    (status?.type === "paused" ||
                      status?.type === "trial" ||
                      status?.type === "active") ? (
                      <>
                        <form action='/create-portal-session' method='POST'>
                          <input type='hidden' id='user' name='user' value={userPipe} />
                          <Button id='checkout-and-portal-button' type='submit'>
                            Manage account
                          </Button>
                        </form>
                      </>
                    ) : card.title !== "Teams" && status?.type === "paying" ? (
                      <>
                        <form action='/change-subscription' method='POST'>
                          <input type='hidden' id='user' name='user' value={userPipe} />
                          <Button id='checkout-and-portal-button' type='submit'>
                            Change plan
                          </Button>
                        </form>
                      </>
                    ) : (
                      <form action='/create-checkout-session' method='POST'>
                        <input type='hidden' name='user' value={userPipe} />
                        <input type='hidden' name='userName' value={user?.name} />
                        <input type='hidden' name='price' value={card.id} />
                        <Button onClick={(e) => handleClick(card.id, e)} type='submit'>
                          {card.title === "Teams"
                            ? "Contact us"
                            : status?.type === "paused" || status?.type === "trial"
                            ? "Subscribe"
                            : status?.type === "canceled"
                            ? "Restart subscription"
                            : "Try for free"}
                        </Button>
                      </form>
                    )}
                  </div>
                </Card.Text>
                <Card.Text style={{ maxWidth: "15rem", margin: "0 1.5rem" }}>
                  <ul>
                    {card?.features?.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {!user && (
        <>
          <Row>
            <Col className='d-flex justify-content-center mt-4'>
              <h2 style={{ color: "#DFC0F2" }}>Start Today - No Credit Card Required!</h2>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col xs={8} className='mt-4'>
              <div>
                Take advantage of our no-strings-attached offer! Sign up now for a free account and
                start creating ten premium articles.
              </div>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col xs={8} className='mt-4 mb-5'>
              <div>
                Experience the edge that AI-researched content offers! Uncover relevant keywords,
                optimize SEO, target the right audience, and much more – all at the touch of a
                button. Get started today with a free, no-obligation trial - no credit card
                required!
              </div>
            </Col>
          </Row>
        </>
      )}
      {status.type === "paused" && (
        <>
          <Row>
            <Col className='d-flex justify-content-center mt-4'>
              <h2 style={{ color: "#DFC0F2" }}>Go to Manage account to start your subscription</h2>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
