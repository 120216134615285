import { Button, Card, CardGroup, Col, Container, Row } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const Home = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/article");
    }
  }, [user]); // Depend on 'user' to re-run the effect when 'user' changes

  const handleClick = () => {
    window.scrollTo(0, 0)
    navigate("/pricing");
  };

  const featuresCards = [
    {
      title: "Empowering Your Unique Voice",
      text: "Embrace the future of content creation with Article Lab. Our innovative AI uses your distinct voice and perspective, generating superior long-form content. We leave you free to hone your message, while we perfectly convey it to your targeted audience.",
    },
    {
      title: "Creating Always Current Content",
      text: "Experience the power of Article Lab's AI as it seamlessly creates articles from 600 to 3000 words. By infusing your content with the latest information, we ensure relevance and richness. Focus on your core message while we deliver timely and detailed content your audience will love.",
    },
    {
      title: "Intelligent Keyword Optimization",
      text: "Article Lab goes beyond content generation. With our unique algorithm, we discern and select the perfect keywords suited to your content, audience, subject, and style. Leverage the benefits of an AI that truly comprehends your voice, enhancing the power of your content.",
    },
  ];
  const blogCards = [
    {
      title: "How Article Lab AI is Making AI Work for Writers and Creators",
      text: "Join the Dark Side (of Writing): How Article Lab AI is Turning Writers into AI-Powered Superheroes! In the age of AI, writing has made significant strides thanks to the development of AI writing tools and software, revolutionizing the way content is created...",
      link: "https://blog.articlelab.ai/2023/05/18/how-article-lab-ai-is-making-ai-work-for-writers-and-creators/",
    },
    {
      title: "Our Love for Tech and the Written Word at Article Lab",
      text: "The Secret Love Affair Between Article Lab and AI: How We Found Our Match in the Digital World The COVID-19 pandemic profoundly impacted the global economy, forcing companies to adapt to new ways of working and interacting with customers. ",
      link: "https://blog.articlelab.ai/2023/05/18/embracing-ai-our-love-for-tech-and-the-written-word-at-article-lab/",
    },
    {
      title: "Discover Article Lab AI: The Next Big Thing in Content Marketing",
      text: "Discover how Article Lab AI can transform your content marketing efforts by leveraging AI-driven content creation, social media marketing, and SEO optimization. Embrace the future of content marketing with Article Lab AI and elevate your brand.",
      link: "https://blog.articlelab.ai/2023/05/14/discover-article-lab-ai-the-next-big-thing-in-content-marketing/",
    },
  ];
  return (
    <Container fluid style={{ marginBottom: "90px" }}>
      <Row className='title'>
        {/*<h2 id="home-header">*/}
        {/*  Unlock rapid document*/}
        {/*  <br /> creation*/}
        {/*</h2>*/}
        {/*<p style={{ paddingLeft: 0 }}>*/}
        {/*  Produce comprehensive, well-researched, up-to-date, and <br /> SEO-Optimized complete*/}
        {/*  articles in Less than 2 minutes*/}
        {/*</p>*/}
        <h2 className='title' id='home-header'>
          Unlock Rapid Content <br /> Creation
        </h2>
        <p style={{ paddingLeft: 40, fontFamily: "Inter, sans-serif" }}>
          Human-Centered. AI-powered.
          <br />
          Prompt-free content creation, audience analysis, and keyword optimization.
          <br />
          Focus on your vision, Article Lab AI will handle the rest.
        </p>
        <Col className='m-0 p-0'>
          <Button style={{ marginLeft: 40 }} className='cta-button' onClick={handleClick}>
            Let's start
          </Button>
          <span className='no-credit'>No credit card required</span>
        </Col>
      </Row>
      <Row
        style={{
          // paddingLeft:40,
          backgroundColor: "#f5f4f6",
          height: "400px",
          position: "absolute",
          left: 0,
          right: 0,
        }}
      ></Row>
      <Row>
        <h2
          //  className="justify-content-center"
          // as="h2"
          style={{
            textAlign: "center",
            fontSize: "4rem",
            fontWeight: "bold",
            fontFamily: "Ubuntu, sans-serif",
            color: "#302848",
            zIndex: 1,
            paddingTop: "4rem",
            paddingBottom: "4rem",
          }}
        >
          Features
        </h2>{" "}
      </Row>
      <Row className='justify-content-center'>
        {featuresCards.map((card, index) => (
          <Col md={3} key={index} className='p-2' style={{display: "grid", justifyContent: "center"}}>
            <Card
              text='dark'
              style={{ maxWidth: "400px", paddingLeft: "15px", paddingRight: "15px" }}
            >
              <Card.Body>
                <Card.Title as='h3' style={{ fontFamily: "Ubuntu, sans-serif" }}>
                  {card.title}
                </Card.Title>
                <Card.Text
                  style={{
                    fontFamily: "Inter, sans-serif",
                    paddingBottom: "15px",
                    paddingTop: "15px",
                  }}
                >
                  {card.text}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {/*<Container fluid>*/}

      {/*</Container>*/}

      <Row style={{ paddingTop: "6rem", paddingLeft: 40, justifyContent: 'space-between' }}>
        <Col lg={5}>
          <Row>
            <h2 style={{ fontSize: "3rem", fontWeight: "bold", fontFamily: "Ubuntu, sans-serif" }}>
              Create Quality Content - Quickly & Easily!
            </h2>
          </Row>
          <Row>
            <p style={{ fontFamily: "Inter, sans-serif", marginTop: "24px" }}>
              Looking to create well-researched articles that are fully optimized for SEO? Look no
              further than our AI article writer. <br />
              With the ability to produce comprehensive, up-to-date articles in less than 2 minutes,
              you can count on us to provide you with top-quality content that is sure to impress
              your audience. Whether you're a content creator, designer, blogger, or researcher,
              we've got everything you need to take your online presence to the next level. <br />
              So why wait? Sign up today and start enjoying all the benefits of our powerful AI
              article writer!
            </p>
            <Col className="m-0 p-0 d-flex" style={{ alignItems: "baseline" }}>
              <Button className='cta-button' style={{ marginBottom: "90px" }} onClick={handleClick}>
                Let's start
              </Button>
              <span className='no-credit'>No credit card required</span>
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          {["Fast", "Easy", "SEO-Optimized"].map((title, index) => (
            <Row className='justify-content-left m-md-2' key={index}>
              <Col>
                <div style={{ display: "flex", marginTop: 18 }}>
                  <img
                    className='lightning'
                    src={`/images/${
                      title === "Fast"
                        ? "bolt_FILL0_wght200_GRAD0_opsz20"
                        : title === "Easy"
                        ? "auto_awesome_FILL0_wght200_GRAD0_opsz20"
                        : "stars_FILL0_wght200_GRAD0_opsz20"
                    }.svg`}
                    alt='lightning'
                    style={{
                      height: "2em",
                      width: "2em",
                      marginRight: "8px",
                    }}
                  />
                  <h5
                    style={{
                      paddingTop: 4,
                      marginLeft: 12,
                      marginBottom: 12,
                      fontFamily: "Ubuntu",
                      fontWeight: "bolder",
                    }}
                  >
                    {title}
                  </h5>
                </div>
                <p style={{ marginTop: 2 }}>
                  With Article Lab, you can{" "}
                  {title === "Fast"
                    ? "quickly and easily create well-researched, up-to-date, and SEO-optimized articles in just a few minutes."
                    : title === "Easy"
                    ? "create high-quality articles in just minutes, without any prior research or writing experience."
                    : "be assured that your articles are well-researched and up-to-date, as well as optimized for SEO."}
                </p>
              </Col>
            </Row>
          ))}
        </Col>
        <Row>
          <Row
            style={{
              backgroundColor: "#f5f4f6",
              height: "400px",
              position: "absolute",
              paddingTop: "7rem",
              paddingBottom: "7rem",
              left: 0,
              right: 0,
            }}
          ></Row>
          <Row
            className='justify-content-center'
            as='h2'
            style={{
              fontSize: "4rem",
              fontWeight: "bold",
              fontFamily: "Ubuntu, sans-serif",
              color: "#302848",
              zIndex: 1,
              position: "relative",
              paddingTop: "4rem",
              paddingBottom: "4rem",
            }}
          >
            Latest Articles
          </Row>
          <Container fluid>
            <Row className='justify-content-center'>
              {blogCards.map((card, index) => (
                <Col md={3} key={index} className='p-3' style={{ display: "grid", justifyContent: "center" }} >
                  <Card
                    text='dark'
                    style={{
                      height: "360px",
                      maxHeight: "360px",
                      maxWidth: "400px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                  >
                    <Card.Body className='card-body-content'>
                      <Card.Title as='h3' style={{ fontFamily: "Ubuntu, sans-serif" }}>
                        {card.title}
                      </Card.Title>
                      <Card.Text
                        className='multiline-text-ellipsis'
                        style={{
                          fontFamily: "Inter, sans-serif",
                          maxHeight: "160px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {card.text}
                      </Card.Text>
                      <div className='d-flex justify-content-end'>
                        <Card.Link style={{ color: "#E17445" }} href={card.link}>
                          Read More
                        </Card.Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </Row>
      </Row>
    </Container>
  );
};
